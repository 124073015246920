@import "../../pages/variables.scss";

.form {
    display: flex;
    flex-direction: row !important;
    justify-content: center;
    width: 100%;
}

.input {
    position: relative;
    font-size: 12px;
    height: 30px;
    width: 60%;

    > input {
        &::placeholder {
            font-size: 12px !important;
            font-family: "Roboto", sans-serif;
            color: $grey-lighter;
            opacity: 1;
        }

        &:last-child {
            background-color: red;
        }
    }
}

.adornment {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 100%;
    cursor: pointer;
}

.select {
    height: 30px;
    width: calc(40% - 10px);
    margin-left: 10px;
    font-size: 12px !important;
    font-family: "Roboto", sans-serif;
    color: $grey-lighter;
    opacity: 1;
}

.chips {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 10px;

    .chip {
        font-size: 12px;
        margin: 2.5px;
    }
}

.smallAutocomplete {
    width: calc(100% - 60px) !important;

    @media screen and (min-width: $tablet-standard-width) {
        width: calc(50% - 50px) !important;
    }
}
